<template>
    <div class="error-wrapper">
        <img src="../../assets/images/section-shape-2.png" alt="Image" class="section-shape-two" />

        <div class="error-wrap">
            <div class="error">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/success.png" alt="Iamge" width="400">
                        <h2 style="color: aliceblue;">Congratulation! You have successfully rest your password.</h2>
                        <p style="color: aliceblue;">Please check your email for the temporary password that will allow you to login and change it in your back office dashboard.</p>
                        <router-link to="/login" class="btn style1">Back To Login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessfulPasswordRestPage',
}
</script>