<template>
    <div style="padding-left: 15px; padding-top: 15px;">
        <Loader v-if="isLoading" />
        <b-container fluid>
            
            <div>Billing Subscriptions</div>

            <div style="margin-bottom: 20px;" class="card-container">
                <!-- <TopTransactionsTable /> -->
            </div>
        </b-container>  
    </div>
</template>

<script>
import Loader from "../../../components/Layouts/Loader.vue"
export default {
    name: 'Billing',
    components: {
        Loader,
    },
    data: () => ({
        
        isLoading: false,
    
    }),
    methods: {
    
     },
}
</script>
<style>

</style>