<template>
    <div style="padding-left: 15px; padding-top: 15px;">
        <Loader v-if="isLoading" />
        <b-container fluid>
            
            <div>API Management & Integration</div>

            <div style="margin-bottom: 20px;" class="card-container-full">
            
            </div>
        </b-container>  
    </div>
</template>

<script>
import Loader from "../../../components/Layouts/Loader.vue"
export default {
    name: 'Developers',
    components: {
        Loader,
    },
    data: () => ({
        
        isLoading: false,
    
    }),
    methods: {
    
     },
}
</script>
<style>
.card-container-full{
    height: 90vh;
    background: var(--card-color);
    border-radius: 10px;
    cursor: pointer;
    color:var(--text-color) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>