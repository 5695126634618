<template>
    <div class="error-wrapper">
        <img src="../../assets/images/section-shape-2.png" alt="Image" class="section-shape-two" />

        <div class="error-wrap">
            <div class="error">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/success.png" alt="Iamge" width="400">
                        <h2 style="color: aliceblue;">Congratulation! You have successfully activated your account</h2>
                        <p style="color: aliceblue;">Please proceed to the login page and use your credentials to enter the back-office and manage your transactions.</p>
                        <router-link to="/login" class="btn style1">Back To Login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuccessfulRegistrationPage',
}
</script>