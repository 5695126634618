<template>
    <div class="error-wrapper">
        <img src="../../assets/images/section-shape-2.png" alt="Image" class="section-shape-two" />

        <div class="error-wrap">
            <div class="error">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/404.png" alt="Iamge">
                        <h2 style="color: aliceblue;">Sorry! Your transaction did not complete successfully</h2>
                        <p style="color: aliceblue;">There could be a number of reasons this could have happened. Please note that no funds have be withdrawn from your card so you are free to try the transaction again.</p>
                        <router-link to="/" class="btn style1">Back To Home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TransactionErrorPage',
}
</script>