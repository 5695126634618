<template>
    <div style="padding-left: 15px; padding-top: 15px;">
      <Loader v-if="isLoading" />
      <b-container fluid>
        <div>User Management</div>
        <div style="margin-bottom: 20px;" class="card-container-full">
          <b-button @click="showModal('add')" variant="success" size="sm">Add New User</b-button>
          
          <b-table striped hover :items="users" :fields="fields" @row-clicked="editUser">
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-container>
  
      <!-- User Modal -->
      <b-modal id="user-modal" v-model="isModalVisible" @hide="resetModal">
        <template v-slot:modal-title>
          {{ modalTitle }}
        </template>
        <b-form @submit.prevent="saveUser">
          <b-form-group label="Name" label-for="user-name">
            <b-form-input
              id="user-name"
              v-model="userForm.name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Email" label-for="user-email">
            <b-form-input
              id="user-email"
              type="email"
              v-model="userForm.email"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import Loader from "../../../components/Layouts/Loader.vue";
  import { BContainer, BButton, BTable, BPagination, BModal, BForm, BFormGroup, BFormInput } from 'bootstrap-vue-3';
  
  export default {
    name: 'Users',
    components: {
      Loader,
      BContainer,
      BButton,
      BTable,
      BPagination,
      BModal,
      BForm,
      BFormGroup,
      BFormInput,
    },
    data() {
      return {
        isLoading: false,
        users: [],
        userForm: {
          id: null,
          name: '',
          email: ''
        },
        isModalVisible: false,
        modalTitle: '',
        currentPage: 1,
        perPage: 5,
        totalRows: 0,
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'email', label: 'Email' },
        ],
      };
    },
    created() {
      this.fetchUsers();
    },
    methods: {
      fetchUsers() {
        // Dummy data for users
        this.users = [
          { id: 1, name: 'John Doe', email: 'john@example.com' },
          { id: 2, name: 'Jane Doe', email: 'jane@example.com' },
          { id: 3, name: 'Alice', email: 'alice@example.com' },
          { id: 4, name: 'Bob', email: 'bob@example.com' },
          { id: 5, name: 'Charlie', email: 'charlie@example.com' },
          { id: 6, name: 'David', email: 'david@example.com' },
          { id: 7, name: 'Eve', email: 'eve@example.com' },
        ];
        this.totalRows = this.users.length;
      },
      showModal(mode, user = {}) {
        if (mode === 'add') {
          this.modalTitle = 'Add New User';
          this.userForm = { id: null, name: '', email: '' };
        } else if (mode === 'edit') {
          this.modalTitle = 'Edit User';
          this.userForm = { ...user };
        }
        this.isModalVisible = true;
      },
      saveUser() {
        if (this.userForm.id) {
          const index = this.users.findIndex(user => user.id === this.userForm.id);
          if (index !== -1) {
            this.users.splice(index, 1, { ...this.userForm });
          }
        } else {
          this.userForm.id = this.users.length + 1;
          this.users.push({ ...this.userForm });
          this.totalRows = this.users.length;
        }
        this.isModalVisible = false;
      },
      editUser(user) {
        this.showModal('edit', user);
      },
      resetModal() {
        this.userForm = { id: null, name: '', email: '' };
      },
      onPageChange(page) {
        this.currentPage = page;
      },
    },
  };
  </script>
  
  <style>
  .card-container-full {
    height: 90vh;
    background: var(--card-color);
    border-radius: 10px;
    cursor: pointer;
    color: var(--text-color) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
  }
  </style>
  